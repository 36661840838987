import { createAction } from "@reduxjs/toolkit";
import { dropdownOptions } from "components/DropDown/BasicDropdown";
import { TypeCurrency } from "constant/currency";

export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>(
  "user/updateUserDarkMode"
);
export const updateCurrency = createAction<{ currency: TypeCurrency }>(
  "user/updateCurrency"
);
export const updateUserWallet = createAction<{
  wallet: `0x${string}` | null | undefined;
}>("user/updateUserWallet");

export const updateUserToken = createAction<{
  JWT: `string` | null | undefined;
}>("user/updateUserToken");
