import { AutoRow, ResponsiveRow } from "components/Row";
import { PageWrapper } from "pages/styled";
import React from "react";
import styled from "styled-components";
import { GraphResponsiveStyledCard } from "../component";
import ActivityMapGraph from "./ActivityMapGraph";
import PolygonBridgingGraph from "./PolygonBridgingGraph";
import LiveActivityTable from "./LiveActivityTable";
import NewActivtyMap from "./NewActivityMap";
import { useZettaActivityCount } from "data/yoots/zetta/ActivityCount";

export default function ActivitySection() {
  const refGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  
  
  const refTable: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
    const activityData = useZettaActivityCount()
    let data = activityData?.records?.length! > 0 ? activityData?.records?.map((item: any,index:number) => {
      return {
        no: index,
        date: new Date(item.data_creation_date)?.getTime() / 1000,
        avg: item.activity_count
      }
    }) : []
    data = data ? data?.reverse() : []
  return (
    <Wrapper>
      <ResponsiveRow width={"100%"} gap="26px">
        <GraphResponsiveStyledCard ref={refGraph}>
          <NewActivtyMap chartData={data}  refGraph={refGraph} />
        </GraphResponsiveStyledCard>
        <GraphResponsiveStyledCard ref={refTable}>
          <PolygonBridgingGraph refGraph={refTable} />
        </GraphResponsiveStyledCard>
      </ResponsiveRow>
      <GraphResponsiveStyledCard>
        <LiveActivityTable />
      </GraphResponsiveStyledCard>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
