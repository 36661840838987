import assets from "assets";
import { SquareIconButton } from "components/Button";
import Column, { AutoColumn } from "components/Column";
import { OutlinedLabel } from "components/DataCard";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { Copy, Globe } from "react-feather";
import styled from "styled-components";
import { colors } from "theme/colors";
import { TEXT } from "theme/texts";
import SocialLinks from "./SocialLinks";
import CustomPieChart from "components/Chart/PieChart";
import { copyTextToClipboard } from "utils/clipboardCopy";
import { useZettaCollectionStats } from "data/yoots/zetta/collectionStats";
import { useSolanaStats } from "data/yoots/y00tsStats";
import { useZettaBridging } from "data/yoots/zetta/BridgingCount";
import axios from "axios";
import { useEffect, useState } from "react";
import { usePolygonListing } from "data/yoots/PolygonListing";


//to generate ellipses in middle of address text
function trimAddress(str) {
  if (str.length > 12) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
}

const ProfileStats = () => {
  const PolygonData = useZettaCollectionStats()
  const solanaData = useSolanaStats()
  const bridged = useZettaBridging()
  console.log("PolygonData", bridged?.records?.[0]?.total_transactions)
  console.log("solanaData", )

  return (
    <ProfileStatsCtr>
      <CustomPieChart
        height={100}
        style={{ width: "clamp(80px, 10vw,100px)" }}
        data={[
          { name: "Polygon", value: (PolygonData?.records && PolygonData?.records[0]?.circulating_supply) || 0, fill: "#9E77ED" },
          { name: "Solana", value: (solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.supply) - ((PolygonData?.records && PolygonData?.records[0]?.circulating_supply) || 0), fill: "#DD8C98" },
        ]}
        series={[]}
      />
      <Column
        style={{
          gap: ".5rem",
        }}
        className="_chartLabels"
      >
        <Row gap=".5rem">
          <svg
            width="clamp(.8rem,2vw,1.35rem)"
            height="clamp(.8rem,2vw,1.35rem)"
            viewBox="0 0 10 10"
            fill="none"
            style={{ flexShrink: "0" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#9E77ED" />
          </svg>
          <TEXT.MediumHeader className="_chartValue">{(PolygonData?.records && PolygonData?.records[0]?.circulating_supply) || 0}</TEXT.MediumHeader>
          <TEXT.Disabled
            className="_chartLabel"
            style={{
              color: "#919191",
            }}
          >
            NFTs bridged to Polygon
          </TEXT.Disabled>
        </Row>
        <Row gap=".5rem" width={"max-content"}>
          <svg
            width="clamp(.8rem,2vw,1.35rem)"
            height="clamp(.8rem,2vw,1.35rem)"
            viewBox="0 0 10 10"
            fill="none"
            style={{ flexShrink: "0" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#DD8C98" />
          </svg>
          <TEXT.MediumHeader className="_chartValue">{(solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.supply) - ((PolygonData?.records && PolygonData?.records[0]?.circulating_supply) || 0)}</TEXT.MediumHeader>
          <TEXT.Disabled
            className="_chartLabel"
            style={{
              color: "#919191",
            }}
          >
            NFTs remaining on Solana
          </TEXT.Disabled>
        </Row>
      </Column>
    </ProfileStatsCtr>
  );
};
const ProfileStatsCtr = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
  ._chartLabels {
    max-width: 50%;
  }
  ._chartValue,
  ._chartLabel {
    white-space: nowrap;
    font-size: clamp(0.9rem, 2vw, 1.5rem);
    flex-shrink: 0;
    overflow: hidden;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-start;   
    
 `}
`;

const ProfileHeader = ({ profileData }) => {
  const Socials = () => (
    <Row gap=".5rem" className="_socialRedirects">
      {profileData?.discord ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.discord}`, "_blank")}
        >
          <GenericImageWrapper
            src={assets.logos.discordWhiteLogo}
            size="1rem"
          />
        </SquareIconButton>
      ) : null}
      {profileData?.twitter ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.twitter}`, "_blank")}
        >
          <GenericImageWrapper src={assets.logos.twitterLogo} size="1rem" />
        </SquareIconButton>
      ) : null}
      {profileData?.website ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.website}`, "_blank")}
        >
          <Globe size="1rem" />
        </SquareIconButton>
      ) : null}
    </Row>
  );
  return (
    <ProfileHeaderCtr>
      <Row gap="1rem" className="_leftCred">
        <CircledImageCtr radius="0" size={"100px"}>
          <img
            style={{ objectFit: "contain" }}
            src={profileData?.image || assets.icons.defaultCollectionIcon}
            alt=""
          />
        </CircledImageCtr>
        <AutoColumn gap="1rem">
          <Row>
            <p className="_cred">
              <TEXT.LargeHeader className="_profileName ">
                {profileData?.name}
              </TEXT.LargeHeader>
              <img src={assets.icons.verifyIcon} alt="" />
            </p>
          </Row>
          <Row gap=".5rem">
            {profileData?.contractAddress && (
              <OutlinedLabel>
                <TEXT.SmallBody
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    copyTextToClipboard(profileData.contractAddress)
                  }
                >
                  {profileData?.contractAddress?.length > 0 &&
                    trimAddress(profileData?.contractAddress)}
                </TEXT.SmallBody>
                <TEXT.Disabled>
                  <Copy size="1rem" />
                </TEXT.Disabled>
              </OutlinedLabel>
            )}
            <OutlinedLabel>
              <TEXT.SmallBody>
                {profileData?.erc_type?.toUpperCase()}
              </TEXT.SmallBody>
            </OutlinedLabel>
            <OutlinedLabel>
              <img
                onClick={() =>
                  window.open(
                    `https://polygonscan.com/address/${profileData?.contractAddress}`,
                    "_blank"
                  )
                }
                src={assets.logos.ethscanLogo}
                alt=""
              />
            </OutlinedLabel>
            <Socials />
          </Row>

          {profileData?.marketplace ? (
            <SocialLinks links={profileData?.marketplace} />
          ) : null}
        </AutoColumn>
      </Row>
      <ProfileStats />
    </ProfileHeaderCtr>
  );
};

const ProfileHeaderCtr = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.disabled};
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction:column; 
  `}
  ._cred {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }
  ._leftCred {
    ._profileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;
    }
    ._profileIcon {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
      flex-direction:column;
      justify-content:flex-start;
      align-items:flex-start;
      ._profileName{
           max-width: 85%;
      }
      `}
  }
  ._socialRedirects {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: fit-content;
  }
`;
export default ProfileHeader;
