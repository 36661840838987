import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../index";
import { updateUserDarkMode, updateUserWallet,updateUserToken } from "./actions";

export class ThemeType {
  static DARK: string = "dark";
  static LIGHT: string = "light";
}

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>();
  const match = window?.matchMedia("(prefers-color-scheme: dark)");
  const theme = match.matches ? ThemeType.DARK : ThemeType.LIGHT;
  let isConnected = false;
  let address = "";

  // useEffect(() => {
  //   if (isConnected && address) {
  //     dispatch(updateUserWallet({ wallet: address }));
  //   } else {
  //     dispatch(updateUserWallet({ wallet: null }));
  //   }
  // }, [dispatch, isConnected]);
  // useEffect(() => {
  //   if (theme.toLowerCase() === "light") {
  //     dispatch(updateUserDarkMode({ userDarkMode: false }));
  //   } else if (theme.toLowerCase() === "dark") {
  //     dispatch(updateUserDarkMode({ userDarkMode: true }));
  //   }
  // }, [dispatch]);

  return null;
}
