import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";

function YootsProtectedRoutes() {
  const { JWT } = useToGetUserInfo();
  return JWT ? <Outlet /> : <Navigate to="/login" />;
}

function AppProtectedRoutes() {
  const { wallet, holdsNFT } = useToGetUserInfo();
  return wallet && holdsNFT ? <Outlet /> : <Navigate to="/" />;
}
const ProtectedRoutes = {
  AppProtectedRoutes,
  YootsProtectedRoutes,
};
export default ProtectedRoutes;
