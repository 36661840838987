import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import {
    ListButtonWrapper,
    ResponsiveGraphHeader,
} from "pages/YootsDashboard/styled";
import { ChainType } from "types";
import { SmallOptionButton } from "components/Button";
import { getChainTypeLogo } from "utils/logo";
import { GenericImageWrapper } from "components/Logo";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import TopHeaderDyanmicRightComponent from "components/HeaderComponent/TopHeaderDynamicRightComponent";
import { GraphResponsiveStyledCard } from "pages/YootsDashboard/component";
import { HorizontalDivider } from "components/Divider/inde";
import { useTopOwners } from "data/yoots/topOwners";
import { ethers,utils,providers } from "ethers";
const CollectionsTableNav = ({ results, page, setPage }: any) => {
    const firstPage = () => {
        if (page > 1) setPage(1);
    };
    const prevPage = () => {
        if (page > 1) setPage((prev: number) => (prev -= 1));
    };

    const nextPage = () => {
        if (page < results.totalPages) setPage((prev: number) => (prev += 1));
    };
    const lastPage = () => {
        if (page < results.totalPages) setPage(results.totalPages);
    };

    const pagesArray = Array(results.totalPages).map((_, index) => index + 1);
    return results?.totalPages > 0 ? (
        <div className="_navs">
            <button className="_staticNavs" onClick={firstPage}>
                First
            </button>
            <nav className="_tableNav">
                <button onClick={prevPage} disabled={page === 0} className="_navBtn">
                    <ArrowLeft size={"1rem"} />
                </button>
                {/* Removed isPreviousData from PageButton to keep button focus color instead */}

                <button className="_navPageBtn">
                    Page {page} of {results.totalPages}
                </button>

                <button
                    onClick={() => nextPage()}
                    disabled={page === results.totalPages}
                    className="_navBtn"
                >
                    <ArrowRight size={"1rem"} />
                </button>
            </nav>
            <button className="_staticNavs" onClick={lastPage}>
                Last
            </button>
        </div>
    ) : null;
};

const TopOnwersFinancialProfileTable = () => {
    //constants
    const SORT_DIR = { ASEC: "asec", DESC: "desc" };

    const SORT_BY = {
        COLLECTION_OWNED: "collection_owned",
        NFT_OWNED: "nft_owned",
        PROFIT: "profit",
    };
    const { currency } = useCurrencyUnit();
    const topOwnersSol = useTopOwners();
    //states
    const [tableData, setTableData] = useState<any>();
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState(null);
    const [sortDir, setSortDir] = useState<string | undefined>();
    const [query, setQuery] = useState(""); //page query endpoint
    const [currentTime, setCurrentTime] = useState(2);
    const [category, setCategory] = useState({
        id: 0,
        name: "All",
    });

    const [AvailableCategories, setAvailableCategories] = useState([]);
    const [chainType, setChainType] = useState(ChainType.polygon);
    const { isMobile } = useMatchBreakpoints();
    const [owners, setOwners] = useState<any>([]);
    const [financialProfiles, setFinancialProfileData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const profileID = '0x670fd103b1a08628e9557cd66b87ded841115190'

    
    //callbacks
    const fetchData = useCallback(async () => {
        let res = await axios.get(
            process.env.REACT_APP_API_URL +
            `/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}`
        );
        setTableData(res.data);
        setAvailableCategories(
            res.data?.category?.map((item: any, index: any) => {
                return {
                    id: index,
                    name: item,
                };
            })
        );
        if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
            setCategory({
                id: 0,
                name: "All",
            });
        }
    }, [currentTime, category, page, query, sortBy, sortDir]);
    const sortItem = ({ sort_by }: any) => {
        setSortBy(sort_by);
        setSortDir(sortDir === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC);
    };
    const FetchEthBalance = async (address:any) => {
        try {
          const provider = new providers.JsonRpcProvider("https://rpc.ankr.com/eth");
          const balance = await provider.getBalance(address);
          return utils.formatEther(balance);
        } catch (error) {
          console.log(error);
          return 0;
        }
      };
    
      const FetchENSName = async (address:any) => {
        try {
          const provider = new providers.JsonRpcProvider("https://rpc.ankr.com/eth");
          const ensName = await provider.lookupAddress(address);
          return ensName;
        } catch (error) {
          console.log(error);
          return address?.slice(0, 6) + "..." + address?.slice(-4);
        }
      };

    const FetchOwners = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/collection/owners/${profileID}`
          );
          let data = res?.data?.data?.TopOwners?.map(async (item:any) => {
            let ensName = false;
            return {
              ...item,
              ens: ensName
                ? ensName
                : item?.wallet?.slice(0, 6) + "..." + item?.wallet?.slice(-4),
            };
          });
          data = await Promise.all(data);
          setOwners(data);
          if (res?.data?.data?.TopOwners?.length > 0) {
            FetchFinancialProfiles(
              res?.data?.data?.TopOwners?.slice(0, 20).map((item:any) => item?.wallet)
            );
          }
        } catch (error) {
          console.log(error);
        }
      };
      const FetchFinancialProfiles = async (wallets:any) => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/financialProfileData/?walletAddress=${wallets}`
          );
          let data = res?.data?.data?.profiles?.map(async (item:any) => {
            let balance = await FetchEthBalance(item?.walletAddress);
            return {
              ...item,
             balance: balance,
            };
          });
          data = await Promise.all(data);
    
          setFinancialProfileData(data);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      };

    
    let polygonData = (financialProfiles?.length > 0 && financialProfiles?.sort((a:any, b:any) => b?.nftsSalesVolume - a?.nftsSalesVolume)?.map((item:any,index:number) => {
        return {
            no: index + 1,
            wallet: item?.walletAddress,
            value: Number(item?.balance),
            solVolume: Number(item?.nftsPurchasesAvgPrice),
            totalNFT: Number(item?.nftsOwnedCount),
            totalUsdValue: Number(item?.nftsSalesVolume),
            diff: Number(item?.nftsTotalNetProfit)
        }
    })?.slice(0, 10)) || [];
    
    
    let solData = (topOwnersSol?.data?.wallets && topOwnersSol?.data?.wallets?.filter((item:any) => item?.totalNftSol > 0)?.map((item: any, index: number) => {
        return {
            no: index + 1,
            wallet: item?.walletMeta?.id,
            value: item?.rankedValue,
            solVolume: item?.solVolume,
            totalNFT: item?.totalNftSol,
            totalUsdValue: item?.totalUsdVal,
            diff: item?.valueDiff1d
        }
    })?.slice(0, 10)) || [];


    const columns = [
        {
            id: 1,
            heading: "",
            value: "no",
        },

        {
            id: 2,
            heading: "Wallet",
            value: "wallet",
            link: "contractAddress",
            logo: "logo",
        },
        {
            id: 4,
            heading: "NFT Owned",
            value: "totalNFT",
            sort_dir: sortDir,
            sort_item: SORT_BY.NFT_OWNED,
        },
        {
            id: 5,
            heading: "Profit",
            value: "diff",
            sort_dir: sortDir,
            sort_item: SORT_BY.PROFIT,
        },
        {
            id: 6,
            heading: "Volume",
            value: "solVolume",
        },
        {
            id: 8,
            heading: "Balance",
            value: "value",
            logo: chainType === ChainType.polygon ? "Ξ ": "◎",
        },
        {
            id: 9,
            heading: "Total USD Value",
            value: "totalUsdValue",
        },
    ];

    useEffect(() => {
        FetchOwners();
    }, []);

    return (
        <CollectionsTableCtr>
            <TopHeaderDyanmicRightComponent
                title="Top Owners Financial Profile"
                tooltip="The overall portfolio overview of the Wallets, based on NFTs and tokens held"
                rightComponent={
                    <div>
                        <ListButtonWrapper withoutDivider={true}>
                            {[ChainType.polygon, ChainType.sol].map((option, index) => {
                                return (
                                    <SmallOptionButton
                                        key={index}
                                        active={chainType === option}
                                        onClick={() => setChainType(option)}
                                    >
                                        <Row gap="4px">
                                            <GenericImageWrapper
                                                src={getChainTypeLogo(option)}
                                                size={isMobile ? "16px" : "12px"}
                                            />
                                            {!isMobile && (
                                                <TEXT.SmallHeader fontWeight={600} fontSize={12} >
                                                    {option}
                                                </TEXT.SmallHeader>
                                            )}
                                        </Row>
                                    </SmallOptionButton>
                                );
                            })}
                        </ListButtonWrapper>

                    </div>}
            />
            {solData || polygonData ? (
                <DataTable
                    column={columns}
                    data={chainType !== "Polygon" ? (solData && solData) : (polygonData && polygonData)}
                    sortItemLabel={sortBy}
                />
            ) : (
                <SKLTCollectionsTable />
            )}
            <HorizontalDivider />

        </CollectionsTableCtr>
    );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopOnwersFinancialProfileTable;
