import assets from "assets";
import { SmallOptionButton, SquareIconButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import { GenericImageWrapper, SnapshotBrandLogoImageWrapper } from "components/Logo";
import QuestionHelper from "components/QuestionHelper";
import Row, { RowBetween } from "components/Row";
import { TabularNumsText } from "components/shared";
import { useTotalNFTMint } from "data/nft/totalNFTMint";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import {
    appRoutes,
    getScrollToElementPath,
    marketOverviewPageElementIDs,
} from "utils/path";
import { ChartTopRightSectionWrapper, ChartWrapper, ListButtonWrapper, ResponsiveGraphHeader } from "../styled";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { useZettaBridging } from "data/yoots/zetta/BridgingCount";

const twitterShareScrollPath = getScrollToElementPath(
    appRoutes.marketOverview,
    marketOverviewPageElementIDs.nftMints
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";



const TopRightComponent = ({
    timeWindow,
    setTimeWindow,
    isMobileView,
}: {
    timeWindow: TimeWindow;
    setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
    isMobileView: boolean;
}) => {
    return (
        <ChartTopRightSectionWrapper>
            <ListButtonWrapper>
                {[
                    TimeWindow.threeMonth,
                    TimeWindow.sixMonth,
                    TimeWindow.oneYear,
                    TimeWindow.all,
                ].map((option, index) => {
                    return (
                        <SmallOptionButton
                            key={index}
                            active={timeWindow === option}
                            onClick={() => setTimeWindow(option)}
                        >
                            {option === "custom" ? (
                                <GenericImageWrapper
                                    src={assets.icons.calendarIcon}
                                    size={isMobileView ? "14px" : "16px"}
                                />
                            ) : (
                                option
                            )}
                        </SmallOptionButton>
                    );
                })}
            </ListButtonWrapper>
        </ChartTopRightSectionWrapper>
    );
};

const TopLeftComponent = ({ count }: { count: number }) => {
    return (
        <AutoColumn gap="4px">
            <TEXT.LargeHeader>
                <TabularNumsText>
                    {`${formatAmount(count).toLocaleUpperCase()} Bridged`}
                </TabularNumsText>
            </TEXT.LargeHeader>
        </AutoColumn>
    );
};

function PolygonBridgingGraph({
    refGraph,
}: {
    refGraph: React.RefObject<HTMLElement>;
}) {
    const theme = useTheme();
    const [volumeHover, setVolumeHover] = useState<number | undefined>();
    const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
    const [leftLabel, setLeftLabel] = useState<string | undefined>();
    const [rightLabel, setRightLabel] = useState<string | undefined>();
    const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
    const totalNFTMints = useTotalNFTMint();
    const reverseTotalNFTMints = [...(totalNFTMints.records ?? [])].reverse();
    const { isMobile } = useMatchBreakpoints();
    const [showBarGraph, setShowBarGraph] = useState(true);

    const downloadScreenshot = useTakeScreenshot({
        ref: refGraph,
        elementIdsTohide: [snapshotDownloadIconId],
        elementIdsToShow: [snapshotBrandLogoId]
    });

    useEffect(() => {
        setLiquidityHover(undefined);
        setVolumeHover(undefined);
    }, []);
    const bridged = useZettaBridging();
    // let BridgedData = () => {
    //     let data = [];
    //     //generate hour date with 0 value
    //     for (let i = 0; i < 24; i++) {
    //         data.push({
    //             dateTime: new Date().setHours(i, 0, 0, 0),
    //             countMints: Math.floor(Math.random() * 10),
    //         });
    //     }
    //    return data;
    // }
    // let bridgedData = BridgedData();
    let BridgedData = bridged.records && bridged.records?.map((item:any) => {
        return {
            dateTime: (item?.date_time),
            countMints: item?.num_transactions,
        }
    });
    let bridgedData = BridgedData || [];
    bridgedData = bridgedData?.reverse();
    /**
     * @notice
     * @dev 'formattedVolumeData' function format data according to graph plot format
     * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
     * @dev 'formattedChartSeries' change according to requirement
     * @param timewindow is selected option out of provided time options to user for filtering chart data
     * @param time will be for x-axis
     * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
     */

    const formattedChartData = useMemo(() => {
        if (bridgedData && bridgedData.length > 0) {
            return bridgedData.map((element) => ({
                time: new Date(element.dateTime),
                valueNFTMint: element.countMints,
            })
            );
        } else {
            return [];
        }
    }, [bridgedData, timeWindow]);

    /**
     * @notice
     * @dev 'formattedChartSeries'  must change according to requirement
     * Add according requirement (This will plot line graph with provided color and data)
     */
    const formattedChartSeries: GenericChartSeries[] = [
        { dataKey: "valueNFTMint", name: "Count", color: theme.graphLines },
    ];

    const filteredTotalCount: number = useMemo(() => {
        if (reverseTotalNFTMints.length === 0) {
            return 0;
        }
        switch (timeWindow) {
            case TimeWindow.threeMonth: {
                const beforeThreeMonthData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(
                        dayjs().subtract(3, "month"),
                        "month"
                    )
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeThreeMonthData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.sixMonth: {
                const beforeSixMonthData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(
                        dayjs().subtract(6, "month"),
                        "month"
                    )
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeSixMonthData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.oneYear: {
                const beforeOneYearData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeOneYearData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.all:
                return reverseTotalNFTMints[0].cumulativeMints;
            default:
                return reverseTotalNFTMints[0].cumulativeMints;
        }
    }, [totalNFTMints, timeWindow]);

    return (
        <>
            <GraphTableTopHeader
                title={"Polygon Bridging"}
                tooltip="Number of NFTs bridged from Polygon to Solana"
                showBarGraph={showBarGraph}
                setShowBarGraph={setShowBarGraph}
                downloadScreenshot={downloadScreenshot}
                isMobileView={isMobile}
                snapshotDownloadIconId={snapshotDownloadIconId}
                snapshotBrandLogoId={snapshotBrandLogoId}
                tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `}
            />
            <HorizontalDivider />
            {showBarGraph ? (
                <ChartWrapper>
                    <BarChart
                        data={formattedChartData}
                        series={formattedChartSeries}
                        yAxisLabel="Number of NFT Bridged"
                        setValue={setVolumeHover}
                        setLabel={setRightLabel}
                        value={volumeHover}
                        label={rightLabel}
                        activeWindow={timeWindow}
                    />
                </ChartWrapper>
            ) : (
                <ChartWrapper>
                    <LineChart
                        data={formattedChartData}
                        series={formattedChartSeries}
                        yAxisLabel="Number of NFT Bridged"
                        setValue={setVolumeHover}
                        setLabel={setRightLabel}
                        value={volumeHover}
                        label={rightLabel}
                        activeWindow={timeWindow}
                    />
                </ChartWrapper>
            )}
        </>
    );
}

export default PolygonBridgingGraph;
