import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { ListButtonWrapper } from "pages/YootsDashboard/styled";
import { ChainType } from "types";
import { SmallOptionButton } from "components/Button";
import { HorizontalDivider } from "components/Divider/inde";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import TopHeaderDyanmicRightComponent from "components/HeaderComponent/TopHeaderDynamicRightComponent";
import { GenericImageWrapper } from "components/Logo";
import { getChainTypeLogo } from "utils/logo";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import {
  appRoutes,
  getScrollToElementPath,
  yootsDashboardElementIDs,
} from "utils/path";
import useTakeScreenshot from "hooks/useTakeSnapshot";

import { useTokensMutual } from "data/yoots/tokensMutuals";
import { useZettaMutualTokens } from "data/yoots/zetta/MutualToken";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.yootsDashabord,
  yootsDashboardElementIDs.listingsAndSalesTable
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";

const CollectionsTableNav = ({ results, page, setPage }: any) => {
  const firstPage = () => {
    if (page > 1) setPage(1);
  };
  const prevPage = () => {
    if (page > 1) setPage((prev: number) => (prev -= 1));
  };

  const nextPage = () => {
    if (page < results.totalPages) setPage((prev: number) => (prev += 1));
  };
  const lastPage = () => {
    if (page < results.totalPages) setPage(results.totalPages);
  };

  const pagesArray = Array(results.totalPages).map((_, index) => index + 1);
  return results?.totalPages > 0 ? (
    <div className="_navs">
      <button className="_staticNavs" onClick={firstPage}>
        First
      </button>
      <nav className="_tableNav">
        <button onClick={prevPage} disabled={page === 0} className="_navBtn">
          <ArrowLeft size={"1rem"} />
        </button>
        {/* Removed isPreviousData from PageButton to keep button focus color instead */}

        <button className="_navPageBtn">
          Page {page} of {results.totalPages}
        </button>

        <button
          onClick={() => nextPage()}
          disabled={page === results.totalPages}
          className="_navBtn"
        >
          <ArrowRight size={"1rem"} />
        </button>
      </nav>
      <button className="_staticNavs" onClick={lastPage}>
        Last
      </button>
    </div>
  ) : null;
};

const TopTokensHeldByUsersTable = ({
  refTable,
}: {
  refTable: React.RefObject<HTMLElement>;
}) => {
  //constants
  const { currency } = useCurrencyUnit();
  const mutalTokesnData = useTokensMutual();
  //states
  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState<string | undefined>();
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState(2);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const [AvailableCategories, setAvailableCategories] = useState([]);
  const [chainType, setChainType] = useState(ChainType.polygon);
  const { isMobile } = useMatchBreakpoints();
  const downloadScreenshot = useTakeScreenshot({
    ref: refTable,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  //callbacks
  const fetchData = useCallback(async () => {
    let res = await axios.get(
      process.env.REACT_APP_API_URL +
        `/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}`
    );
    setTableData(res.data);
    setAvailableCategories(
      res.data?.category?.map((item: any, index: any) => {
        return {
          id: index,
          name: item,
        };
      })
    );
    if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
      setCategory({
        id: 0,
        name: "All",
      });
    }
  }, [currentTime, category, page, query, sortBy, sortDir]);
  const PolygonData = useZettaMutualTokens();
  let PolygonTableData =
    (PolygonData?.records?.[0]?.erc20_token &&
      PolygonData?.records
        ?.map((item: any, index: any) => {
          return {
            no: index + 1,
            token_name: item?.erc20_token,
            wallet: item?.mutual_count,
          };
        })
        ?.slice(0, 10)) ||
    [];
  PolygonTableData = PolygonTableData?.length > 0 ? PolygonTableData?.filter((item: any) => !item?.token_name?.includes('0x')) : PolygonTableData;
  let solanaData =
    (mutalTokesnData?.data &&
      mutalTokesnData?.data?.data
        ?.filter((item: any) => item?.address)
        ?.map((item: any, index: any) => {
          return {
            no: index + 1,
            token_name: item?.name,
            wallet: item?.num,
            mean_usd: item?.mean,
            median_usd: item?.median,
          };
        })
        ?.slice(0, 10)) ||
    [];

  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Tokens",
      value: "token_name",
    },
    {
      id: 3,
      heading: "Wallet",
      value: "wallet",
    },
    // {
    //     id: 4,
    //     heading: "Mean USD value",
    //     value: "mean_usd",
    // },
    // {
    //     id: 5,
    //     heading: "Median USD value",
    //     value: "median_usd",
    // },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CollectionsTableCtr>
      {/* <GraphTableTopHeader
                title="Top Tokens held by users"
                isMobileView={isMobile}
                tooltip="Common tokens held by the holders of Yoots"
                tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `}

            />
            <div
              style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                padding: "0 1rem",
                 
              }}
           >
              <ListButtonWrapper withoutDivider={true}>
                  {[ChainType.polygon, ChainType.sol].map((option, index) => {
                      return (
                          <SmallOptionButton
                              key={index}
                              active={chainType === option}
                              onClick={() => setChainType(option)}
                          >
                              <Row gap="4px">
                                  <GenericImageWrapper
                                      src={getChainTypeLogo(option)}
                                      size={isMobile ? "16px" : "12px"}
                                  />
                                  {!isMobile && (
                                      <TEXT.SmallHeader fontWeight={600} fontSize={12} >
                                          {option}
                                      </TEXT.SmallHeader>
                                  )}
                              </Row>
                          </SmallOptionButton>
                      );
                  })}
              </ListButtonWrapper>

          </div> */}
      <TopHeaderDyanmicRightComponent
        title="Top Tokens held by users"
        tooltip="Common tokens held by the holders of Yoots"
        rightComponent={
          <div>
            <ListButtonWrapper withoutDivider={true}>
              {[ChainType.polygon, ChainType.sol].map((option, index) => {
                return (
                  <SmallOptionButton
                    key={index}
                    active={chainType === option}
                    onClick={() => setChainType(option)}
                  >
                    <Row gap="4px">
                      <GenericImageWrapper
                        src={getChainTypeLogo(option)}
                        size={isMobile ? "16px" : "12px"}
                      />
                      {!isMobile && (
                        <TEXT.SmallHeader fontWeight={600} fontSize={12}>
                          {option}
                        </TEXT.SmallHeader>
                      )}
                    </Row>
                  </SmallOptionButton>
                );
              })}
            </ListButtonWrapper>
          </div>
        }
      />
      {solanaData || PolygonData ? (
        <DataTable
          column={columns}
          data={chainType === ChainType.polygon ? PolygonTableData : solanaData}
          sortItemLabel={sortBy}
        />
      ) : (
        <SKLTCollectionsTable />
      )}
      <HorizontalDivider />
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopTokensHeldByUsersTable;
