import { useQuery } from '@apollo/client'
import { y00tsStats } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_Y00TS_STATS = gql`
  query records {
    records {
        market_cap_eth,
        market_cap_usd,
        market_cap_matic,
        avg_price_eth,
        avg_price_usd,
        avg_price_matic,
        circulating_supply,
        floor_price_eth,
        floor_price_usd,
        floor_price_matic,
        nft_listed,
        nft_unique_owners,
        nfts_bridged_from_solana,
        volume_eth,
        volume_matic,
        volume_usd
    }
  }
`

interface y00tsStatsData {
    records: {
        market_cap_usd: number
        floor_price_eth: number
        floor_price_usd: number
        avg_price_usd: number
        avg_price_eth: number
        avg_price_matic: number
        volume_usd: number
        volume_eth: number
        volume_matic: number
        circulating_supply: number
        nft_unique_owners: String
        market_cap_eth: number
        market_cap_matic: number
        nfts_bridged_from_solana: String
        nft_listed: String
        floor_price_matic: number
    }[]
}

/**
 * Fetch matic current price
 */

export function useZettaCollectionStats(): {
    loading: boolean
    error: boolean,
    records: {
        market_cap_usd: number
        floor_price_eth: number
        floor_price_usd: number
        avg_price_usd: number
        avg_price_eth: number
        avg_price_matic: number
        volume_usd: number
        volume_eth: number
        volume_matic: number
        circulating_supply: number
        nft_unique_owners: String
        market_cap_eth: number
        market_cap_matic: number
        nfts_bridged_from_solana: String
        nft_listed: String
        floor_price_matic: number
    }[] | undefined
} {
    const { loading, error, data } = useQuery<y00tsStatsData>(QUERY_Y00TS_STATS, {
        client: y00tsStats,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        market_cap_eth: p.market_cap_eth,
                        market_cap_usd: p.market_cap_usd,
                        market_cap_matic: p.market_cap_matic,
                        avg_price_eth: p.avg_price_eth,
                        avg_price_usd: p.avg_price_usd,
                        avg_price_matic: p.avg_price_matic,
                        circulating_supply: p.circulating_supply,
                        floor_price_eth: p.floor_price_eth,
                        floor_price_usd: p.floor_price_usd,
                        floor_price_matic: p.floor_price_matic,
                        nft_listed: p.nft_listed,
                        nft_unique_owners: p.nft_unique_owners,
                        nfts_bridged_from_solana: p.nfts_bridged_from_solana,
                        volume_eth: p.volume_eth,
                        volume_matic: p.volume_matic,
                        volume_usd: p.volume_usd
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
