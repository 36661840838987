import assets from "assets";
import { SecondaryButton } from "components/Button";
import Column from "components/Column";
import { HideMedium, ShowMedium } from "components/Hide";
import { StyledInput } from "components/Input/TextField";
import { CircledImageCtr } from "components/Logo";
import Row from "components/Row";
import useTheme from "hooks/useTheme";
import { FormEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import axios from "axios";
import { useUserJWT } from "store/user/hooks";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const YootsLogin = () => {
  const theme = useTheme();
  const formRef = useRef<HTMLFormElement>(null);
  const { JWT, setJWT } = useUserJWT();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const recordUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { key: e.target.name, val: e.target.value };
    setFormData((prev) => ({ ...prev, [data.key]: data.val }));
  };
  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("submitted data", formData);
    LoginAPI();
  };

  useEffect(() => {
    let JWTLocal = localStorage.getItem("JWT");
    if (JWTLocal) {
      setJWT(JWTLocal);
    }
    if (JWT || JWTLocal) {
      navigate("/");
    }
  }, [JWT, navigate]);

  const LoginAPI = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        formData
      );
      console.log(res?.data?.token);
      setJWT(res?.data?.token);
      localStorage.setItem("JWT", res?.data?.token);
      navigate("/");
      formRef?.current?.reset();
    } catch (error) {
      console.log(error);
      alert("Invalid Credentials");
      formRef?.current?.reset();
    }
  };

  return (
    <LoginCtr>
      <SBLogo>
        <img src={assets.logos.layerELogo} alt="" />
      </SBLogo>
      <YootsLogo>
        <img src={assets.logos.yootsLogo} alt="" />
      </YootsLogo>
      <PageBG>
        <img src={assets.images.bgYoots} alt="" />
      </PageBG>
      <LeftPage>
        <YootsBGWrapper>
          <img src={assets.images.bgSBDashboard} alt="" />
        </YootsBGWrapper>
      </LeftPage>
      <RightPage>
        <Column style={{ maxWidth: "420px", gap: "1rem" }}>
          <TEXT.LargeHeader>
            Welcome to y00ts <br />
            Custom Dashboard
          </TEXT.LargeHeader>
          <LoginForm onSubmit={onFormSubmit} ref={formRef}>
            <StyledInput
              type={"email"}
              name="email"
              placeholder="Enter email address"
              style={{ borderRadius: ".25rem", background: `${theme.bgModal}` }}
              onChange={recordUserInput}
              required
            />
            <StyledInput
              type={"password"}
              name="password"
              placeholder="Enter password"
              style={{ borderRadius: ".25rem", background: `${theme.bgModal}` }}
              onChange={recordUserInput}
              required
            />
            <SecondaryButton borderRadius=".25rem">Submit</SecondaryButton>
          </LoginForm>
        </Column>
      </RightPage>
    </LoginCtr>
  );
};
const SBLogo = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  overflow: hidden;
  width: 150px;
  img {
    max-width: 100%;
    object-fit: contain;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
margin:0 auto;
left:0;
right:0;
  `}
`;
const YootsLogo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;
const PageBG = styled.div`
  overflow: hidden;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  width:100%;
  `}
`;
const LoginCtr = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction:column;
  `}
`;
const YootsBGWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    transform: translateX(30%);
    scale: 0.9;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  display:none;
  `}
`;
const RightPage = styled(Column)`
  padding: 1rem;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 50%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     height:50%;
       max-width: 100%;
       padding-top:2rem;
  justify-content:flex-start;
  text-align:center;
  `}
`;
const LoginForm = styled.form`
  display: grid;
  gap: 1rem;
  padding-top: 2rem;
`;
const LeftPage = styled(Column)`
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 1rem;
  max-width: 50%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   height:50%;
     max-width: 100%;
  `}
`;

export default YootsLogin;
